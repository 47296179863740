<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from 'axios';
const moment = require('moment');
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import _ from "lodash";

/**
 * Elements component
 */
export default {
  page: {
    title: "Form Ticket Gangguan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, DatePicker },
  data() {
    return {
      title: "Ticket's Customers",
      items: [
      ],
      userId: null,
      submittedForm: false,
      userName: null,
      hasData: false,
      settingId: '',
      data: {
        category: '',
        problems: '',

        stage: {
          code: 'ticketSubmitted',
          createdAt: moment().format(),
          createdBy: this.$store.state.authfack.user.username
        },
        status: 'open',
        priority: '',
        customerId: '',
        customerProduct: '',
        deadlineInstallation: '',
        assignmentGroup: [],
        isCustomer: false
      },
      choice: {
        customerProduct: {
          code: '',
          label: 'Pilih'
        },
        category: {
          code: '',
          label: 'Pilih'
        },
        priority: {
          code: '',
          label: 'Pilih'
        }
      },
      categoryList: [
        {
          label: 'Internet',
          code: 'internet'
        },
        {
          label: 'Administration',
          code: 'admin'
        },
        {
          label: 'Installation',
          code: 'installation'
        }
      ],
      priorityList: [
        {
          label: 'Low',
          code: 'low'
        },
        {
          label: 'High',
          code: 'high'
        }
      ],
      listCustomer: [],
      listProduct: [],
      kategoriAltInstal: false,
      runTroubleShoot: false,
      troubleShoot: false,
      cardTroubleShooting: false,
      selectedDateTime: {
        date: null,
        time: null, // Untuk menyimpan waktu yang dipilih
      },
      compoAcordion: {
        acordion1: false,
        acordion2: false,
        acordion3: false,
        acordion4: false,
        acordion5: false,
      },
      openTime: false,

      StateTroubleShootValue: {
        oltRx: null,
        onuRx: null,
        onuSignal: null,
        onuIP: null,
        onuUptime: null,
        onuState: null,
        result: null,
        code: null,
        checkingPayment: null,
        ticketId: null,
        stage_id: null
      },
      stateTroubleShoot: {
        oltRx: null,
        onuRx: null,
        signal: null,
        onuIP: null,
        upTime: null,
        status: null,
        result: null
      },

      loadingStateTroubleShoot: {
        oltRx: true,
        onuRx: true,
        signal: true,
        onuIP: true,
        upTime: true,
        status: true,
        result: true
      },
      fieldsDatakategori: [

        {
          label: "Nomor Ticket",
          key: "noTicket",
          sortable: true,
          thStyle: { width: "13%" },
        },
        {
          label: "Nama Pelanggan",
          key: "customerName",
          sortable: true,
        },
        {
          label: "Order Id",
          key: "orderId",
          sortable: true,
        },
        {
          label: "Status Ticket",
          key: "status",
          sortable: true,
          thStyle: { width: "10%" },
        },

        {
          label: "Prioritas Ticket",
          key: "priority",
          sortable: true,
          thStyle: { width: "10%" },
        },

        {
          label: "Kategori",
          key: "category",
          sortable: true,
        },
        {
          label: "Assignment",
          key: "assignmentGroup",
          sortable: true,
          thStyle: { width: "13%" },

        },
        {
          label: "Dibuat Pada",
          key: "createdAt",
          sortable: true,
        },
        {
          label: "",
          key: "action",
          thStyle: { width: "8%" },
        }
      ],
      // kategoriData: {
      //   kategoriAdminData: null,
      //   kategoriAdminDataHigh: null,
      //   kategoriAdminDataLow: null,
      //   kategoriInstalationData: null,
      //   kategoriInstalationDataHigh: null,
      //   kategoriInstalationDataLow: null,
      //   kategoriInternetData: null,
      //   kategoriInternetDataHigh: null,
      //   kategoriInternetDataLow: null
      // },
      kategoriData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterDateTime: null,
      selectedPetugas: [],
      selectedPetugasCount: 1,
      holderIdPetugas: null,
      optionsPetugas: null,
      holderUpdateAssigment: null,
      paginationCountSkip: 0,
      advFilterQuery: {
        status: null,
        kategori: null,
        date: null,
        priority: null,
        isCustomer: null,
      },
      advFilterParamOn: false,
      regexSearchParamOn: false,
      regexHolder: "",
      regexIdentifierSearch:false,

    };
  },
  validations: {
    data: {
      customerProduct: {
        required
      },
      priority: {
        required
      },
      category: {
        required
      },
      problems: {
        required
      }
    }
  },
  async created() {

  },

  sockets: {
    connect: function () {
      console.log('socket connected ticket')
    },
    updateTicket: async function () {
      


      // await this.refresh()
      let pageCount = this.perPage + 1
      let url = "analaytic-dashboard-ticket/tabel?$skip=" + this.paginationCountSkip + "&$limit=" + pageCount
      this.kategoriData = await axios.get(process.env.VUE_APP_URL_FEATHERS + url).then(response => {
       
        // this.kategoriData.push(response.data.ticketDataPlusDetailMountly.dataTicketMountly)
        return response.data.ticketDataPlusDetailMountly.dataTicketMountly

      })
    },



  },


  async mounted() {
    let loader = this.$loading.show({
      loader: 'spinner',
      color: '#ff5024'
    });
    await this.getTicketData()



    loader.hide()

    const loggeduser = localStorage.getItem('user');
    const jsonUser = JSON.parse(loggeduser)
    this.userName = jsonUser.username
    await this.getDataPetugas()
    this.userId = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'login?username=' + jsonUser.username)
      .then(response => {
        return response.data.data[0]._id
      })



    // await axios.get(process.env.VUE_APP_URL_FEATHERS + 'user-setting?$populate=user')
    //         .then(response => {
    //           if (response.data.total != 0){
    //             this.settingId = response.data.data[0]._id
    //             this.hasData = true
    //             this.data = response.data.data[0]
    //           }
    //       }) 
  },
  watch: {
    'choice.customerProduct': {
      //immediate: true,
      handler(newValue) {
        if (newValue === 0 || newValue.code != '') {
          //  console.log('VAL', newValue)
          this.data.customerProduct = newValue.code
        }
      }
    },
    'choice.category': {
      //immediate: true,
      handler(newValue) {
       
        if (newValue.code == "installation") {
          this.kategoriAltInstal = true
        }
        else {
          this.kategoriAltInstal = false
        }
        if (newValue === 0 || newValue.code != '') {
          //  console.log('VAL', newValue)
          this.data.category = newValue.code


          if (this.data.category != "installation") {
            this.troubleShoot = true
            // this.runTroubleShoot = true
          }
          else {
            this.troubleShoot = false
            // this.runTroubleShoot = true
          }

        }
        this.choice.priority = ''
        // this.runTroubleShoot = false
      }
    },
    'choice.priority': {
      //  immediate: true,

      handler(newValue) {
       
        if (newValue === 0 || newValue.code != '') {
          //  console.log('VAL', newValue)
          console.log("isi", this.data.priority);
          this.data.priority = newValue.code


        }

      }
    },
    'selectedDateTime': {
      deep: true,
      handler(value) {
        if (value.time != null && value.date != null) {

          let dateString = `${value.date} ${value.time}`;
          const datetime = moment(dateString).format('YYYY-MM-DD HH:mm:ss')
          this.data.deadlineInstallation = datetime
        }

      }
    },
    'selectedPetugas': {
      deep: true,
      handler(value) {
        // Pastikan this.holderSelectedIdPetugas adalah array yang sesuai
        if (!Array.isArray(this.holderSelectedIdPetugas)) {
          this.holderSelectedIdPetugas = [];

        }
      

        if (this.kategoriAltInstal == true) {
          this.runTroubleShoot = true
        }

        for (let index = 0; index < value.length; index++) {
          for (let index2 = 0; index2 < this.optionsPetugas.length; index2++) {
            if (this.optionsPetugas[index2] === value[index]) {
              // Pastikan array holderSelectedIdPetugas memiliki ukuran yang sesuai
              this.holderSelectedIdPetugas[index] = this.holderIdPetugas[index2];
            }
          }
        }

      }
    },




  },
  methods: {

    async getTicketData() {
     
      this.regexIdentifierSearch= false
      this.regexHolder =""
      let pageCount = this.perPage + 1
      let url = "analaytic-dashboard-ticket/tabel?$skip=" + this.paginationCountSkip + "&$limit=" + pageCount
      this.kategoriData = await axios.get(process.env.VUE_APP_URL_FEATHERS + url).then(response => {
      
        // this.kategoriData.push(response.data.ticketDataPlusDetailMountly.dataTicketMountly)
        return response.data.ticketDataPlusDetailMountly.dataTicketMountly

      })
     
    },

    async refresh() {
      let pageCount = this.perPage + 1
      let url = "analaytic-dashboard-ticket/tabel?$skip=" + this.paginationCountSkip + "&$limit=" + pageCount
      this.kategoriData = await axios.get(process.env.VUE_APP_URL_FEATHERS + url).then(response => {
       
        // this.kategoriData.push(response.data.ticketDataPlusDetailMountly.dataTicketMountly)
        return response.data.ticketDataPlusDetailMountly.dataTicketMountly

      })
    },

    async paginateRequest() {
      this.skipCount()
      let pageCount = 0
      pageCount = this.perPage + 1

      if (this.advFilterParamOn == true) {
        this.filterTicket()
      }
      else {
        let url = "analaytic-dashboard-ticket/tabel?$skip=" + this.paginationCountSkip + "&$limit=" + pageCount
        await axios.get(process.env.VUE_APP_URL_FEATHERS + url).then(response => {
         

          this.kategoriData = this.kategoriData.concat(response.data.ticketDataPlusDetailMountly.dataTicketMountly)
         
          return this.kategoriData

        })
      }




      // console.log("paginationCountSkip",this.paginationCountSkip);

    },


    async getCustomer(loading, search, vm) {
      vm.listCustomer = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'customer-data?searchCustomer=' + search)
        .then(response => {
         
          
          loading(false);
          return response.data
        })
    },
    handleChange(value, type) {
      
      if (type === 'second') {
        this.openTime = false;
      }
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.getCustomer(loading, search, this);
      }
    },
    async setSelectedCustomer(value) {
      this.data.customerId = value.id
      this.listProduct = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'costumer-products?customerIdTicket=' + value.id)
        .then(response => {
          return response.data.map(function (val) {
            return {
              label: val.orderId,
              code: val.id
            }
          })
        })
    },
    async setSelectedProduct(value) {
      // console.log('prodyc', value)
      this.data.customerProduct = value.code
    },
    rows() {
      return this.kategoriData.length;
    },
    async submit() {

      this.data.assignmentGroup = this.holderSelectedIdPetugas

      this.submittedForm = true;
      this.$v.data.$touch();
      if (this.$v.data.$invalid) {


        // console.log('gagal', this.data)
        return;
      } else {

        let loader = this.$loading.show({
          // Optional parameters
          container: null,
          canCancel: false,
          color: '#1976D2'
        });
        loader.hide()


        await axios.post(process.env.VUE_APP_URL_FEATHERS + 'ticket', this.data)
          // eslint-disable-next-line no-unused-vars
          .then(async response => {
            this.StateTroubleShootValue.ticketId = response.data.ticketNo
            this.StateTroubleShootValue.code = response.data.stage.code
            this.StateTroubleShootValue.stage_id = response.data.stage[0].createdAt
            if (_.has(this.cardOptionResult, 'checkingPayment')) {
              this.StateTroubleShootValue.checkingPayment = this.cardOptionResult.checkingPayment
            }



            if (this.data.category != "installation") {
             
              await axios.post(process.env.VUE_APP_URL_FEATHERS + 'stage-detail-troubleshot', this.StateTroubleShootValue).then(res2 => {
                return res2;
              })
            }

            Swal.fire("Sukses!", "Data berhasil disimpan", "success");
            this.$router.push({ path: '/ticket' })
            this.$refs['modal-tambah-ticket'].hide()
          }).catch(error => {
            loader.hide()
            Swal.fire("Gagal!", "Data gagal disimpan, " + error.message, "error");
          })
        loader.hide()
      }
    },
    async runTroubleShootMethod(row, item) {
      console.log('asdasdasd', this.data)
      this.runTroubleShoot = true
      this.resetLoadingState()
      this.data.assignmentGroup = this.holderSelectedIdPetugas
     
      if (row != null) {

        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'stage-detail-troubleshot?troubleshot=' + row.orderId).then(async response => {
          this.resetLoadingState()

          this.cardOptionResult = response.data
          this.StateTroubleShootValue.checkingPayment = this.cardOptionResult.checkingPayment
          this.StateTroubleShootValue.onuIP = this.cardOptionResult.onuIP
          this.StateTroubleShootValue.ticketId = row.noTicket
          this.StateTroubleShootValue.code = item.stageData.code
          this.StateTroubleShootValue.rating = response.data[0].rating
          this.cardOptionResult.ticketId = row.noTicket
          this.cardOptionResult.code = item.stageData.code
          this.cardOptionResult.stage_id = item.stageData._id
          if (row.stage.length >= 1) {
            this.StateTroubleShootValue.stage_id = item.stageData._id

          }
          else {
            this.StateTroubleShootValue.stage_id = item.stageData._id
          
          }
          await this.showLoadingOverlay()
          this.holderRowData = row
          return response
        })
      }
      //this.runTroubleShoot = false
      else {
        this.runTroubleShoot = true
        this.troubleShootLoading = true
        this.cardTroubleShooting = true
        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'ticket-analytic?troubleshot=' + this.data.customerProduct).then(response => {
          this.cardOptionResult = response.data
         
          this.StateTroubleShootValue.checkingPayment = this.cardOptionResult.checkingPayment
          this.StateTroubleShootValue.onuIP = this.cardOptionResult.onuIP
          this.StateTroubleShootValue.rating = this.cardOptionResult.rating
          this.showLoadingOverlay()
          return response
        })
      }
      //

    },
    async showLoadingOverlay() {
      this.runTroubleShoot = false

      await setTimeout(() => {

      }, 0);
      // Simulate some asynchronous operation, e.g., fetching data
      setTimeout(() => {
        this.loadingStateTroubleShoot.signal = false

        this.StateTroubleShootValue.onuSignal = this.cardOptionResult.onuSignal
        if (this.StateTroubleShootValue.onuSignal == "baik") {
          this.stateTroubleShoot.signal = true
        }
        else {
          this.stateTroubleShoot.signal = false
        }
      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {

        this.loadingStateTroubleShoot.oltRx = false;

        this.StateTroubleShootValue.oltRx = this.cardOptionResult.oltRx
        if (this.StateTroubleShootValue.onuSignal == "baik") {
          this.stateTroubleShoot.oltRx = true
        }
        else {
          this.stateTroubleShoot.oltRx = false
        }


      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {
        this.loadingStateTroubleShoot.onuRx = false;
        this.StateTroubleShootValue.onuRx = this.cardOptionResult.onuRx

        if (this.StateTroubleShootValue.onuSignal == "baik") {
          this.stateTroubleShoot.onuRx = true
        }
        else {
          this.stateTroubleShoot.onuRx = false
        }


      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {


        this.loadingStateTroubleShoot.status = false;
        this.StateTroubleShootValue.onuState = this.cardOptionResult.onuState

        if (this.StateTroubleShootValue.onuState == "working") {
          this.stateTroubleShoot.status = true
        }
        else {
          this.stateTroubleShoot.status = false
        }

      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {
        this.loadingStateTroubleShoot.upTime = false;
        this.StateTroubleShootValue.onuUptime = this.cardOptionResult.onuUptime
        if (this.StateTroubleShootValue.onuUptime != null) {
          this.stateTroubleShoot.upTime = true
        }
        else {
          this.stateTroubleShoot.upTime = false
        }

      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {
        this.loadingStateTroubleShoot.onuIP = false;
        this.StateTroubleShootValue.onuIP = this.cardOptionResult.onuIP
        if (this.StateTroubleShootValue.onuIP != null) {
          this.stateTroubleShoot.onuIP = true
        }
        else {
          this.stateTroubleShoot.onuIP = false
        }

      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {
        this.loadingStateTroubleShoot.status = false;
        this.StateTroubleShootValue.result = this.cardOptionResult.result
        this.runTroubleShoot = true
      }, 0); // Simulating a 2-second loading delay

    },
    handleRowClicked(item) {
      // console.log(item);
     
      let id = item.id
      this.$router.push({ path: '/ticket/ticketDetail', query: { id } })
    },

    resetLoadingState() {
      this.stateTroubleShoot = {
        oltRx: null,
        onuRx: null,
        signal: null,
        onuIP: null,
        upTime: null,
        status: null,
        result: null
      },

        this.loadingStateTroubleShoot = {
          oltRx: true,
          onuRx: true,
          signal: true,
          onuIP: true,
          upTime: true,
          status: true,
          result: true
        },

        this.StateTroubleShootValue = {
          oltRx: null,
          onuRx: null,
          onuSignal: null,
          onuIP: null,
          onuUptime: null,
          onuState: null,
          result: null,
          code: null,
          checkingPayment: null,
          ticketId: null,
          stage_id: null
        }

    },

    tambahTicket() {
      this.$refs['modal-tambah-ticket'].show()
    },
    hideModaltambahTicket() {
      this.$refs['modal-tambah-ticket'].hide()


      this.choice.customerProduct = {
        code: '',
        label: 'Pilih'
      },
        this.choice.category = {
          code: '',
          label: 'Pilih'
        },
        this.choice.priority = {
          code: '',
          label: 'Pilih'
        }

    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      if (filteredItems.length === 0 || filteredItems === null) {
        // Jika tidak ada data yang ditemukan, lakukan tindakan yang diperlukan
        console.warn('Tidak ada data yang ditemukan.');
        // Contoh: Set totalRows ke 0 dan tetap di halaman 1
        this.totalRows = 0;
        this.currentPage = 1;
      } else {
        // Jika ada data yang ditemukan, update jumlah totalRows dan reset currentPage
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      }
    },

    async getDataPetugas() {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'analaytic-dashboard-ticket?petugas=true').then(response => {
        this.holderIdPetugas = response.data.idPetugas
        return this.optionsPetugas = response.data.dataPetugas
      })


    },
    updateAssigementSeleceted(index) {
      this.holderSelectedIdPetugas.splice(index, 1)
      this.selectedPetugas.splice(index, 1)
      this.selectedPetugasCount--
    },

    async filterTicket() {
      let pageCount = this.perPage + 1
      if (this.advFilterParamOn == false) {
        this.kategoriData = null
        this.paginationCountSkip = 0
      }


      this.advFilterParamOn = true
      let query = "?advFilter=true&$skip=" + this.paginationCountSkip + "&$limit=" + pageCount

      if (this.advFilterQuery.status !== null) {
        query = query + "&status=" + this.advFilterQuery.status
      }
      if (this.advFilterQuery.kategori !== null) {
        query = query + "&category=" + this.advFilterQuery.kategori
      }
      if (this.advFilterQuery.priority !== null) {
        query = query + "&priority=" + this.advFilterQuery.priority

      }
      if (this.advFilterQuery.date !== null) {
        let date = moment(this.advFilterQuery.date).format("MM-DD-YYYY")
        query = query + "&createdAt[$gte]=" + date

      }

      if (this.advFilterQuery.isCustomer !== null) {
        query = query + "&isCustomer=" + this.advFilterQuery.isCustomer
      }
      
      if (this.kategoriData == null) {
       
        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'analaytic-dashboard-ticket/tiketDetail' + query).then(response => {
          console.log('asdjaksdj', response.data)
          this.kategoriData = response.data.ticketDataPlusDetailMountly.dataTicketMountly
          console.log(this.kategoriData)
          return this.kategoriData
        })
      }
      else {
        console.log('asdjaksdj1')
        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'analaytic-dashboard-ticket/tiketDetail' + query).then(response => {
          this.kategoriData = this.kategoriData.concat(response.data.ticketDataPlusDetailMountly.dataTicketMountly)
          return this.kategoriData
        })
        
      }
      console.log('home',this.kategoriData)

    },
    async resetFilter() {
     
      this.advFilterQuery = {
        status: null,
        kategori: null,
        date: null,
        priority: null,
        isCustomer: null,
      }
      this.compoAcordion = {
        acordion1: false,
        acordion2: false,
        acordion3: false,
        acordion4: false,
        acordion5: false,
      }
      this.advFilterParamOn = false
      await this.getTicketData()
    },
    async skipCount() {

      this.paginationCountSkip = 0

      this.paginationCountSkip = (this.currentPage) * this.perPage

      return this.paginationCountSkip
    },

    async regexSearch() {
      let query = ""
      
      this.advFilterParamOn = false
     
      
      if (this.regexHolder.length >= 3 ) {
        query = query + '&regex=' + this.regexHolder
       
        let pageCount = this.perPage + 1
        let url = "analaytic-dashboard-ticket/tabel?$skip=" + this.paginationCountSkip + "&$limit=" + pageCount+ query
        this.kategoriData = await axios.get(process.env.VUE_APP_URL_FEATHERS + url).then(response => {
         
          this.regexIdentifierSearch = true
          // this.kategoriData.push(response.data.ticketDataPlusDetailMountly.dataTicketMountly)
          return response.data.ticketDataPlusDetailMountly.dataTicketMountly

        })

      }
      else if (this.regexHolder.length < 3 && this.regexIdentifierSearch == true){
        await this.getTicketData()
        this.regexIdentifierSearch=false
      }
      
    }
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <b-card>
        <div class="row ">
          <div class="col-md-12 col-md-12">
            <div class="row">
              <div class="col-md-2">
                <b-card>
                  <b-row>
                    <b-col md="12">
                      <b-button variant="primary" class="w-100 d-flex align-items-center justify-content-center"
                        @click="tambahTicket()">
                        <span class="mdi mdi-clipboard-plus-outline me-2"></span>
                        Tambahkan Ticket
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card class="md">

                  <h4 class="card-title">Filter Tiket</h4>
                  <p class="card-title-desc">Terapkan pencarian pada field dibawah ini</p>
                  <div class="custom-accordion">

                    <div class="card" md>

                      <a href="javascript: void(0);" class="collapsed text-dark" data-toggle="collapse"
                        v-b-toggle.checkout-shippinginfo-collapse>
                        <div class="p-0">
                          <div class="media align-items-center">
                            <div class="me-3">
                              <i class="uil uil-calender text-primary h2"></i>
                            </div>
                            <div class="media-body overflow-hidden">
                              <h4 class="font-weight-bold font-size-12">Status Ticket</h4>
                              <p class="text-muted text-truncate mb-0">

                              </p>

                            </div>
                            <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                          </div>
                        </div>
                      </a>

                      <b-collapse id="checkout-shippinginfo-collapse" v-model="compoAcordion.acordion1">

                        <div class="p-2 border-top">
                          <div class="card border-4">
                            <div class="row-12">
                              <b-form-checkbox v-model="advFilterQuery.status" value="open"> &nbsp;Open</b-form-checkbox>
                              <b-form-checkbox v-model="advFilterQuery.status" value="closed">
                                &nbsp;Close</b-form-checkbox>
                            </div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>

                    <div class="card">
                      <a href="javascript: void(0);" class="collapsed text-dark" data-toggle="collapse"
                        v-b-toggle.checkout-billinginfo-collapse>
                        <div class="p-0">
                          <div class="media align-items-center">
                            <div class="me-3">
                              <i class="bx bx-map-pin text-primary h2"></i>
                            </div>
                            <div class="media-body overflow-hidden">
                              <h2 class="font-weight-bold font-size-13">Kategori Ticket</h2>
                              <p class="text-muted text-truncate mb-2">

                              </p>

                            </div>
                            <i class="mdi mdi-chevron-up accor-down-icon font-size-22"></i>
                          </div>
                        </div>
                      </a>

                      <b-collapse id="checkout-billinginfo-collapse" v-model="compoAcordion.acordion2">

                        <div class="pr-2 border-top-3 mt-3">
                          <div class="card border-4 ">
                            <b-form-checkbox v-model="advFilterQuery.kategori"
                              value="internet">&nbsp;Internet</b-form-checkbox>
                            <b-form-checkbox v-model="advFilterQuery.kategori"
                              value="installation">&nbsp;Instalation</b-form-checkbox>
                            <b-form-checkbox v-model="advFilterQuery.kategori" value="admin">&nbsp;Admin</b-form-checkbox>
                          </div>
                        </div>
                      </b-collapse>
                    </div>

                    <div class="card">
                      <a href="javascript: void(0);" class="collapsed text-dark" data-toggle="collapse"
                        v-b-toggle.checkout-iseng-collapse>
                        <div class="p-0">
                          <div class="media align-items-center">
                            <div class="me-3">
                              <i class="uil uil-calender text-primary h2"></i>
                            </div>
                            <div class="media-body overflow-hidden">
                              <h2 class="font-weight-bold font-size-13 mt-2">Tanggal Ticket</h2>
                              <p class="text-muted text-truncate mb-2">

                              </p>

                            </div>
                            <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                          </div>
                        </div>
                      </a>

                      <b-collapse id="checkout-iseng-collapse" v-model="compoAcordion.acordion3">

                        <div class="pr-2 border-top-2 mt-3">
                          <div class="card border-4 ">
                            <date-picker v-model="advFilterQuery.date" :default-value="new Date()" type="date"
                              placeholder="Select date" class="mb-1">&nbsp;</date-picker>
                          </div>


                        </div>
                      </b-collapse>
                    </div>
                    <div class="card">
                      <a href="javascript: void(0);" class="collapsed text-dark" data-toggle="collapse"
                        v-b-toggle.checkout-createdBy-collapse>
                        <div class="p-0">
                          <div class="media align-items-center">
                            <div class="me-3">
                              <i class="uil uil-pricetag-alt text-primary h2"></i>
                            </div>
                            <div class="media-body overflow-hidden">
                              <h2 class="font-weight-bold font-size-13 mt-2">Prioritas Ticket</h2>
                              <p class="text-muted text-truncate mb-2">

                              </p>

                            </div>
                            <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                          </div>
                        </div>
                      </a>

                      <b-collapse id="checkout-createdBy-collapse" v-model="compoAcordion.acordion4">

                        <div class="pr-2 border-top-3 mt-3">
                          <div class="card border-4 ">
                            <b-form-checkbox v-model="advFilterQuery.priority" value="high">&nbsp;High</b-form-checkbox>
                            <b-form-checkbox v-model="advFilterQuery.priority" value="low">&nbsp;Low</b-form-checkbox>
                          </div>

                        </div>
                      </b-collapse>
                    </div>
                    <div class="card">
                      <a href="javascript: void(0);" class="collapsed text-dark" data-toggle="collapse"
                        v-b-toggle.checkout-rangePrice-collapse>
                        <div class="p-0">
                          <div class="media align-items-center">
                            <div class="me-3">
                              <i class="uil uil-pricetag-alt text-primary h2"></i>
                            </div>
                            <div class="media-body overflow-hidden">
                              <h2 class="font-weight-bold font-size-13 mt-2">Dibuat Oleh</h2>
                              <p class="text-muted text-truncate mb-2">

                              </p>

                            </div>
                            <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                          </div>
                        </div>
                      </a>

                      <b-collapse id="checkout-rangePrice-collapse" v-model="compoAcordion.acordion5">

                        <div class="pr-2 border-top-3 mt-3">
                          <div class="card border-4 ">
                            <b-form-checkbox v-model="advFilterQuery.isCustomer"
                              value="false">&nbsp;Admin</b-form-checkbox>
                            <b-form-checkbox v-model="advFilterQuery.isCustomer"
                              value="true">&nbsp;Pelanggan</b-form-checkbox>
                          </div>

                        </div>
                      </b-collapse>
                    </div>
                    <div class="card">
                      <div class="d-flex flex-wrap gap-3">
                        <!-- Tombol "Terapkan" -->
                        <button type="button" class="btn btn-success waves-effect waves-light w-100"
                          @click="filterTicket()">
                          <i class="mdi mdi-magnify me-1"></i><span class="ms-1">Terapkan</span>
                        </button>

                        <!-- Tombol "Reset" -->
                        <button type="button" class="btn btn-warning waves-effect w-100" @click="resetFilter()">
                          <i class="mdi mdi-restart me-1"></i><span class="ms-1">Reset</span>
                        </button>
                      </div>
                    </div>

                  </div>

                </b-card>
              </div>



              <div class="col-md-10">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title"></h4>
                      <div id="tickets-table_length" class="dataTables_length">
                        <div class="row">
                          <div class="col-md-7">
                            <label class="d-inline-flex align-items-center fw-normal">
                              Show&nbsp;
                            </label>
                            <b-form-select v-model="perPage" :options="pageOptions" size="sm"
                              class="mr-6"></b-form-select>&nbsp;entries &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                          <div class="col-md-5">
                            <b-form-group label-for="filter-input" label-cols-sm="6" label-align-sm="right"
                              label-size="sm" class="mb-0 ">
                              <b-input-group size="sm" class="mb-2 flaot-end" >
                                <b-form-input id="filter-input" ref ="filterRegex" v-model="regexHolder" type="text" 
                                  placeholder="No Tiket" size="sm"
                                  @keyup ="regexSearch()"></b-form-input>
                                <b-input-group-append>
                                  <b-button class="float-end" :disabled="!regexHolder" @click="getTicketData">Clear</b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </div>
                        </div>
                      </div>

                      <b-table head-variant="light" :items="kategoriData" :fields="fieldsDatakategori" show-empty striped
                        outlined class="mb-1" responsive="md" :per-page="perPage" :current-page="currentPage"
                        :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered"
                        @row-clicked="handleRowClicked">

                        <template v-slot:cell(status)="data">
                          <div class="badge rounded-pill bg-soft-success font-size-14 font-weight-bold" :class="{
                            'bg-soft-success': data.item.status === 'closed',
                            'bg-soft-danger': data.item.status === 'open'
                          }">
                            {{ data.item.status }}
                          </div>
                        </template>


                        <template v-slot:cell(priority)="data">
                          <div class="badge rounded-pill bg-soft-success font-size-14 font-weight-bold" :class="{
                            'bg-soft-danger': data.item.priority === 'high',
                            'bg-soft-warning': data.item.priority === 'low'
                          }">
                            {{ data.item.priority }}
                          </div>
                        </template>

                        <template v-slot:cell(action)="data">
                          <span class="float-end text-danger mdi mdi-bell-alert font-size-16"
                            v-if="data.item.isRead == false && data.item.status === 'open'"><i
                              class="font-size-13">Baru</i></span>
                          <span class="float-end text-warning mdi mdi-bell-alert font-size-16"
                            v-if="data.item.isDataPerbaikan == false && data.item.status === 'closed'"><i
                              class="font-size-13">Lengkapi</i></span>


                        </template>

                        <template v-slot:cell(assignmentGroup)="data">
                          <div v-if="data.item.assignmentGroup.length != 0">
                            <div class="petugas-container">

                              <span v-for="(petugas, index) in data.item.assignmentGroup" :key="petugas._id"
                                class="fas fa-user" style="font-size: large; display: inline-block; margin: 0 5px;"
                                :id="`popover-${index}-${data.item.id}`">
                                <b-popover :target="`popover-${index}-${data.item.id}`" triggers="hover" placement="top">
                                  {{ petugas }}
                                </b-popover>
                              </span>

                            </div>
                          </div>
                          <div v-else>
                            <span class="fas fa-user-alt-slash" style="color: red;" id="popover-kosong"></span>
                            <b-popover target="popover-kosong" triggers="hover" placement="top">
                              Data Petugas Belum Diatur
                            </b-popover>
                          </div>
                        </template>










                        <template #empty>
                          <h5>Tidak ada data</h5>
                        </template>
                        <template #table-busy>
                          <div class="text-center  my-2">
                            <b-spinner class="xs-1" variant="primary"></b-spinner>
                            <strong>Memuat data...</strong>
                          </div>
                        </template>

                        <template>

                        </template>

                      </b-table>
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage" :total-rows="rows()" :per-page="perPage"
                            :hide-goto-end-buttons=true @page-click="paginateRequest">
                          </b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>





      </b-card>
      <!-- end col -->
    </div>
    <!-- end row -->
    <b-modal id="modal-center" ref="modal-tambah-ticket" centered title="Tiket Baru" title-class="font-18" size="md"
      hide-footer @hidden="hideModaltambahTicket()">
      <div class="p-2 pt-0 border-top">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">Textual inputs</h4>
            <p class="card-title-desc">
              Here are examples of
              <code>.form-control</code> applied to each textual HTML5
              <code>&lt;input&gt;</code>
              <code>type</code>.
            </p> -->

            <div class="row">
              <div class="col-12">
                <b-alert show v-if="$v.data.$invalid && submittedForm" variant="danger">Mohon isi data dengan
                  benar!</b-alert>
                <form @submit.prevent="submit" class="form-horizontal" role="form">
                  <div class="form-group row mb-4">
                    <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Nama </label>
                    <div class="col-sm-9">
                      <v-select label="customerName" :filterable="false" :options="listCustomer" @search="onSearch"
                        @input="setSelectedCustomer">
                        <template slot="no-options">
                          Ketik nama pelanggan..
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.customerName }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.customerName }}
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <div class="form-group row mb-4" v-if="data.customerId != ''">
                    <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Order ID</label>
                    <div class="col-sm-9">
                      <v-select label="label" v-model="choice.customerProduct" :options="listProduct">
                      </v-select>
                    </div>
                  </div>
                  <div class="form-group row mb-4" v-if="listProduct.length > 0">
                    <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Kategori</label>
                    <div class="col-sm-9">
                      <v-select label="label" v-model="choice.category" :options="categoryList">
                      </v-select>
                    </div>
                  </div>

                  <div class="form-group row mb-2" v-if="kategoriAltInstal">
                    <!-- <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">preketek</label> -->
                    <!-- <div class="col-sm-9"> -->
                    <div class="pl-0">
                      <b-form-group label="Deadline Pemasangan Baru">

                        <div class="form-group row mb-4" v-if="listProduct.length > 0">
                          <label for="horizontal-firstname-input" class="col-sm-3 col-form-label"
                            style="margin-left: ‒20px;">Tanggal</label>
                          <div class="col-sm-9">
                            <date-picker v-model="selectedDateTime.date" value-type="format" format="YYYY-MM-DD"
                              type="date" placeholder="Select date" class="mb-1"></date-picker>
                          </div>
                        </div>

                        <div class="form-group row mb-2" v-if="listProduct.length > 0">
                          <label for="horizontal-firstname-input" class="col-sm-3 col-form-label"
                            style="margin-left: ‒20px;">Waktu</label>
                          <div class="col-sm-9">
                            <date-picker v-model="selectedDateTime.time" value-type="format" type="time"
                              :open.sync="openTime" placeholder="Select time" @change="handleChange"></date-picker>

                          </div>
                        </div>



                      </b-form-group>

                    </div>
                    <!-- </div> -->
                  </div>

                  <div class="form-group row mb-2" v-if="listProduct.length > 0">
                    <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Prioritas</label>
                    <div class="col-sm-9">
                      <v-select label="label" v-model="choice.priority" :options="priorityList">
                      </v-select>
                    </div>
                  </div>
                  <div class="form-group row mb-2" v-if="listProduct.length > 0">
                    <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Petugas</label>
                    <div class="col-sm-9">
                      <div v-for="(item, index) in selectedPetugasCount" :key="selectedPetugasCount[index]" class="mt-2">
                        <transition name="fade-in">
                          <div>
                            <b-form-select v-model="selectedPetugas[index]" :options="optionsPetugas" lazy
                              style="width:85%;height:70%;"></b-form-select>
                            <b-button size="sm" variant="danger"
                              style="height: 45%;margin-left: 0.313rem;border-bottom-width: 0px;border-top-width: 0px;border-left-width: 0px;border-right-width: 0px;"
                              class="ml-1" @click="updateAssigementSeleceted(index)"
                              v-if="index != selectedPetugasCount - 1"><i class="dripicons-minus"> </i>
                            </b-button>

                            <b-button size="sm" variant="success"
                              style="height: 45%;margin-left: 0.313rem;border-bottom-width: 0px;border-top-width: 0px;border-left-width: 0px;border-right-width: 0px;"
                              class="ml-1" @click="selectedPetugasCount++" v-if="index == selectedPetugasCount - 1"><i
                                class="dripicons-plus"> </i></b-button>
                          </div>
                        </transition>

                      </div>
                    </div>
                  </div>

                  <div class="mt-2 mb-2 text-center">

                    <b-button size="md" variant="info" style="margin-right: 0.313rem;"
                      v-if="troubleShoot && choice.category !== 'installation'" @click="runTroubleShootMethod()"> Cek
                      Kendala
                    </b-button>
                  </div>



                  <div class="mt-2 mb-2 text-center" v-if="cardTroubleShooting">
                    <b-card>
                      <!-- <b-spinner label="Loading..." v-if="troubleShootLoading"></b-spinner> -->
                      <div class="text-start ">
                        <h5 class="mb-3">Hasil Pengecekan</h5>
                        <h6>Optical Network Unit</h6>
                        <ul>

                          <li>
                            <div>
                              <b-input-group class="mb-2">
                                <b-input-group-prepend style="margin-right: 1.25rem;">
                                  <b-button variant="primary" size="sm" class="mr-4" disabled
                                    pill>&nbsp;&nbsp;Signal&nbsp;</b-button>
                                </b-input-group-prepend>

                                <b-form-input size="sm" style="width: 50%;" :state="stateTroubleShoot.signal"
                                  :value="StateTroubleShootValue.onuSignal"></b-form-input>
                                <b-input-group-append>
                                  <div class="loading-overlay" style="margin-top: 6px;"
                                    v-if="loadingStateTroubleShoot.signal"></div>

                                </b-input-group-append>

                              </b-input-group>
                            </div>
                          </li>
                          <li>
                            <div>
                              <b-input-group class="mb-2">
                                <b-input-group-prepend style="margin-right: 1.25rem;">
                                  <b-button variant="primary" size="sm" class="mr-2" disabled
                                    pill>&nbsp;&nbsp;oltRx&nbsp;&nbsp;&nbsp;</b-button>
                                </b-input-group-prepend>

                                <b-form-input size="sm" style="width: 10%;" :state="stateTroubleShoot.oltRx"
                                  class="float-end" :value="StateTroubleShootValue.oltRx"></b-form-input>

                                <b-input-group-append>
                                  <div class="loading-overlay" v-if="loadingStateTroubleShoot.oltRx"></div>

                                </b-input-group-append>
                              </b-input-group>
                            </div>
                          </li>
                          <li>
                            <div>
                              <b-input-group class="mb-2">
                                <b-input-group-prepend style="margin-right: 1.25rem;">
                                  <b-button variant="primary" size="sm" class="mr-4" disabled
                                    pill>&nbsp;&nbsp;onuRx&nbsp;</b-button>
                                </b-input-group-prepend>

                                <b-form-input size="sm" style="width: 50%;" :state="stateTroubleShoot.onuRx"
                                  :value="StateTroubleShootValue.onuRx"></b-form-input>
                                <b-input-group-append>
                                  <div class="loading-overlay" style="margin-top: 6px;"
                                    v-if="loadingStateTroubleShoot.onuRx"></div>

                                </b-input-group-append>

                              </b-input-group>
                            </div>
                          </li>

                          <li>
                            <div>
                              <b-input-group class="mb-2">
                                <b-input-group-prepend style="margin-right: 1.25rem;">
                                  <b-button variant="warning" size="sm" class="mr-4" disabled
                                    pill>&nbsp;&nbsp;Status&nbsp; </b-button>
                                </b-input-group-prepend>

                                <b-form-input size="sm" style="width: 50%;" :state="stateTroubleShoot.status"
                                  :value="StateTroubleShootValue.onuState"></b-form-input>
                                <b-input-group-append>
                                  <div class="loading-overlay" style="margin-top: 6px;"
                                    v-if="loadingStateTroubleShoot.status"></div>

                                </b-input-group-append>

                              </b-input-group>
                            </div>
                          </li>

                          <li>
                            <div>
                              <b-input-group class="mb-2">
                                <b-input-group-prepend style="margin-right: 1.125rem;" :state="true">
                                  <b-button variant="warning" size="sm" class="mr-4" disabled pill>Up Time
                                  </b-button>
                                </b-input-group-prepend>

                                <b-form-input size="sm" style="width: 52%;" :state="stateTroubleShoot.upTime"
                                  :value="StateTroubleShootValue.onuUptime"></b-form-input>
                                <b-input-group-append>
                                  <div class="loading-overlay" style="margin-top: 6px;"
                                    v-if="loadingStateTroubleShoot.upTime"></div>

                                </b-input-group-append>

                              </b-input-group>
                            </div>
                          </li>
                          <li>
                            <div>
                              <b-input-group class="mb-2">
                                <b-input-group-prepend style="margin-right: 1.2rem;">
                                  <b-button variant="warning" size="sm" class="mr-4" disabled pill>&nbsp;Onu
                                    IP&nbsp;</b-button>
                                </b-input-group-prepend>

                                <b-form-input size="sm" style="width: 50%;" :state="stateTroubleShoot.onuIP"
                                  :value="StateTroubleShootValue.onuIP"></b-form-input>
                                <b-input-group-append>
                                  <div class="loading-overlay" style="margin-top: 6px;"
                                    v-if="loadingStateTroubleShoot.onuIP"></div>

                                </b-input-group-append>

                              </b-input-group>
                            </div>
                          </li>
                          <li>
                            <div class="accordion text-center" role="tablist">
                              <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                  <b-button block v-b-toggle.accordion-1 variant="info" disabled>Hasil
                                    TroubleShooting</b-button>

                                </b-card-header>
                                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                  <b-card-body style="padding-left:0px;">
                                    <ul>
                                      <li class="mb-3" v-for="items in StateTroubleShootValue.result" :key="items">
                                        <b-card-text class="text-left" align="left">{{ items }}</b-card-text>
                                      </li>

                                    </ul>
                                  </b-card-body>

                                </b-collapse>
                              </b-card>
                            </div>
                          </li>


                        </ul>
                      </div>
                    </b-card>
                  </div>

                  <div class="form-group row mb-4" v-if="listProduct.length > 0">
                    <label for="horizontal-firstname-input" class="col-md-3 col-form-label">Detail</label>
                    <div class="col-sm-9">
                      <textarea class="form-control" id="productdesc" rows="3" v-model="data.problems"></textarea>
                    </div>
                  </div>

                  <div class="mt-4">
                    <b-button type="submit" variant="primary" v-if="runTroubleShoot">Simpan</b-button>
                  </div>
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<style>
img {
  height: auto;
  max-width: 2.5rem;
  margin-right: 1rem;
}

.d-center {
  display: flex;
  align-items: center;
}

.selected img {
  width: auto;
  max-height: 23px;
  margin-right: 0.5rem;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active>a {
  color: #fff;
}

.custom-icon {
  font-size: 1.25rem;
  /* Adjust the font size as needed */
  margin-top: 0.313rem;
  padding-left: 0.313rem;

}

.input-container {
  position: relative;
}

.loading-overlay {
  position: absolute;
  width: 1.163rem;
  height: 1.163rem;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: #333;
  animation: spin 1s infinite;
}

.row-closed {
  background-color: #d4edda;
}

.row-open {
  background-color: #f8d7da;
}

.priority-high {
  border-left: 5px solid #dc3545;
}

.priority-low {
  border-left: 5px solid #ffc107;
}


.boarder-notif-true {
  background: transparent;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgb(241, 79, 79);
  border-radius: 0.1rem;
  transition: border-color 3s ease;
}

.boarder-notif-false {
  background: transparent;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgb(79, 241, 95);
  border-radius: 0.1rem;
  transition: border-color 3s ease;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 1s ease;
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}
</style>